<template>
  <div id="app">
    <img class="banner" src="./assets/banner.jpg" />
    <div class="header">
      <div class="v_title">沈阳森博网络科技有限公司</div>
      <span
        class="v_select"
        :class="currIndex === 0 ? 'bg_yellow' : 'bg_aliceblue'"
        @click="onClick(0)"
        >首页</span
      >
      <span style="color: #ffffff">|</span>
      <span
        class="v_select"
        :class="currIndex === 1 ? 'bg_yellow' : 'bg_aliceblue'"
        @click="onClick(1)"
        >产品</span
      >
      <span style="color: #ffffff">|</span>
      <span
        class="v_select"
        :class="currIndex === 2 ? 'bg_yellow' : 'bg_aliceblue'"
        @click="onClick(2)"
        >关于我们</span
      >
    </div>
    <div class="v_content" v-if="currIndex == 0">
      <div style="flex-direction: column; margin-left: 15%">
        <div style="font-size: 35px">简介</div>
        <div style="font-size: 25px">Introduce</div>
        <p style="font-size: 15px; margin-top: 15px; line-height: 25px">
          公司致力于在线阅读产品的开发与运营，依托在阅读领域多年积累的经验，公司自主开发了“蚂蚁阅读”app和快应用产品，与国内外多家知名版权公司合作，经过不断的探索和完善，在产品、技术、内容、运营、推广等方面逐渐形成了自己独特的风格和模式，现公司拥有经验丰富的小说产品运营团队和强大的技术开发队伍，未来将致力于数字阅读平台运营、内容制作发行、阅读产品开发、IP孵化、游戏影视改编等工作。
        </p>
      </div>
      <img
        style="margin-right: 25%; margin-left: 15%; width: 200px; height: 230px"
        src="./assets/bg03.jpg"
        alt=""
      />
    </div>
    <div class="v_prodect" v-if="currIndex == 1">
      <div style="margin-left: 15%; margin-right: 15%">
        <div style="font-size: 35px">蚂蚁阅读</div>
        <div style="font-size: 15px; margin-top: 15px; line-height: 25px">
          蚂蚁阅读是一款超好用的小说阅读手机软件，聚集了众多海量小说任意阅读，支持一键搜索，轻松阅读全集，随时随地，畅快阅读！蚂蚁阅读收录了小说类目，包含了都市言情、玄幻奇幻、武侠仙侠、官场权势、青春校园、穿越架空、悬疑、科幻灵异职场励志等海量原创小说，全都能够满足你！
        </div>
      </div>
      <div class="pro_img_view">
        <img src="./assets/bg05.png" alt="" />
        <img src="./assets/bg06.png" alt="" />
        <img src="./assets/bg07.png" alt="" />
        <img src="./assets/bg08.png" alt="" />
      </div>
    </div>
    <div class="v_content_About" v-if="currIndex == 2">
      <div style="flex-direction: column">
        <div style="font-size: 35px; margin-top: 30px">关于我们</div>
        <div style="font-size: 25px">About us</div>
        <div class="v_text">
          公司在小说阅读领域经过多年发展，现已在技术、产品、运营、内容领域积累丰富的经验，利用自己强大的产品开发能力和丰富的内容分发渠道，与内容版权公司合作，将优秀的作品快速投入市场，并取得不错的效果。并且公司拥有自己的产品开发团队和版权运营团队，未来将在小说IP孵化、游戏影视剧改编、小说周边等多领域发展。
        </div>
        <div class="v_text">
          1. 深耕阅读市场，拓展小说领域
          公司多年来致力于移动互联网电子阅读产品的开发与运营，在团队建设、分发渠道、技术开发，产品运营等方面积累了丰富的经验，在此基础上向小说领域延伸，开发多款小说类产品，依托公司成熟的推广渠道和强大的技术产品实力，将继续在小说领域拓展更多业务。
        </div>
        <div class="v_text">
          2. 资深的技术、内容、产品、运营、推广团队
          公司在原有基础上，拓展app开发、前端开发、大数据分析等人员，不断打磨产品，并吸纳小说领域资深运营、产品，不断创新运营策略，拥有优秀的设计师团队和推广团队，有力的推进了公司的发展。
        </div>
        <div class="v_text">
          3. 互利共赢，协同发展
          公司在小说内容上在自己设计创作外，与国内外各大漫画作者和小说公司合作，对其内容包装定位，利用自己的渠道对其推广，达到合作共赢的结果，未来将还会合作开发IP及其周边产品，创造更大的合作价值。
        </div>
        <div class="v_text">
          4. IP孵化，创作无限想象
          公司通过自主开发设计和与其它小说公司合作，对爆款小说内容进行影视剧、游戏改编，并利用移动新媒体的力量，以短视频、图文、有声、直播等形式进行推广和IP的培育，打造包含小说图文、小说视频、有声及小说、手游、小说周边、小说广告、小说教育等多方面的产业链。
        </div>
      </div>
    </div>
    <div class="footr">
      <div>
        <p style="font-size: 10px">
          CopyRight © 2022 - 2023 沈阳森博网络科技有限公司
          版权所有备案编号：<a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备2022001799号-1</a>
        </p>
        
        <p style="font-size: 10px">
          公司地址：辽宁省沈阳市沈北新区道义南大街37号科技园3号楼3-78
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currIndex: 0,
    };
  },
  methods: {
    onClick(i) {
      this.currIndex = i;
    },
  },
};
</script>

<style >
html,body{
	margin: 0;
}
#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.banner {
  width: 100%;
  height: 580px;
}
.header {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15%;
  width: 100%;
}
.v_title {
  font-size: 30px;
  color: aliceblue;
  font-weight: 600;
  margin-right: 100px;
}
.v_select {
  cursor: pointer;
  margin: 0% 5%;
  font-size: 15px;
  font-weight: 700;
}
.bg_yellow {
  color: yellow;
}
.bg_aliceblue {
  color: aliceblue;
}
.v_content {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}
.swper_view {
  display: flex;
  flex-direction: row;
}
.v_prodect {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.pro_img_view {
  display: flex;
  justify-content: space-around;
  margin-left: 15%;
  margin-right: 15%;
  /*height: 450px;*/
  margin-top: 30px;
  margin-bottom: 30px;
}
.pro_img_view img{
	width: 22%;
	height: auto;
	border: 1px solid #dfdfdf;
}
.v_content_About {
  flex-direction: column;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 10px;
}
.footr {
  display: flex;
  justify-content: center;
  align-items: center;
  background: darkgray;
  height: 100px;
}
.index_view {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.v_text {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
